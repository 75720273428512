import { useState, useContext } from "react";
import NavigationContext from "./Context/navigation";
import "./App.css";
import Route from "./componets/Route";
import SplashLogo from "./componets/SplashLogo";
import MenuBar from "./componets/MenuBar";
import JumpButtonOutline from "./images/JumpButtonOutline";
import Footer from "./componets/Footer";
import ServicesPage from "./pages/Services/Services";
import WorkPage from "./pages/Work/Work";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import FourOFourPage from "./pages/404/404Page";

function App() {
  const { currentPath, navigate } = useContext(NavigationContext);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [deltaX, setDeltaX] = useState(0);

  const setGallery = (status) => {
    setIsGalleryOpen(!status);
  };

  const nav = ["/services", "/work", "/about", "/contact"];

  const handleTouchStart = (e) => {
    setClientX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    setDeltaX(0);
    if (!isGalleryOpen) setDeltaX(e.changedTouches[0].clientX - clientX);
  };
  if (deltaX > 110) {
    if (currentPath === "/" || currentPath === "/services") {
      navigate("/contact");
    } else {
      navigate(nav[nav.indexOf(currentPath) - 1]);
    }
    setDeltaX(0);
  }
  if (deltaX < -110) {
    if (currentPath === "/") {
      navigate("/work");
    } else if (currentPath === "/contact") {
      navigate("/services");
    } else {
      navigate(nav[nav.indexOf(currentPath) + 1]);
    }
    setDeltaX(0);
  }

  return (
    <div className="background">
      <div className="splash-screen">
        <SplashLogo />
        <div className="orange-bar" id="app" />
        <a href="#app">
          <JumpButtonOutline className="jump-button" />
        </a>
      </div>
      <div className="main-content-container">
        <MenuBar swipe={deltaX} />
        <div
          className="page-container"
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
        >
          <Route path="/">
            <ServicesPage />
          </Route>
          <Route path="/services">
            <ServicesPage />
          </Route>
          <Route path="/work">
            <WorkPage setGallery={(e) => setGallery(e)} />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/404">
            <FourOFourPage />
          </Route>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
