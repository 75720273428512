import "./404Page.css";
import MissingPageIcon from "./MissingPageIcon";

function FourOFourPage() {
  return (
    <div className="flex-container">
      <div className="four-text-container">
        <h1>404 Error</h1>
        <h4>Well something happened</h4>
        <p>Either something broke, or you went to a page that doesn't exist.</p>
      </div>
      <MissingPageIcon className="missing-icon" alt="missing page icon" />
    </div>
  );
}

export default FourOFourPage;
