import "../Services/Services.css";
import Card from "../../componets/Card";
import ReactLogo from "./ReactLogo";
import CollegeM from "./CollegeM";
import Jurmu from "./Jurmu";

function AboutPage() {
  const items = [
    {
      title: "The latest technology",
      icon: <ReactLogo alt="React Logo" className="card-icon" />,
      description:
        "We use the latest standards for Websites and web apps. We use the React framework, the advantages are it’s robust, flexible, blazing fast, and we can integrate your site with the other technologies your business already uses. Having a web site isn’t enough anymore, it needs to be fast. If a site doesn't load within 3 seconds most people click the back button! Stop wasting your S.E.O dollars and quit losing sales!",
    },
    {
      title: "Small town service",
      icon: <CollegeM alt="React Logo" className="card-icon" />,
      description:
        "Based out of beautiful Bozeman Montana we work hard and play hard. Being from a small town we know customer service and good word of mouth are essential for business of any size. Keeping your marketing on point and your website's uptime reflect on your both our businesses.",
    },
    {
      title: "Our Flexible Team",
      icon: <Jurmu alt="John Jurmu" className="card-icon" />,
      secondHeading: "Small But Mighty",
      description:
        "A small and flexible team with almost 10 years in marketing and design. John Jurmu is the lead Developer and owner, has worked on several campaigns for different sized companies. During that time he has built an extensive network of contractors, no matter what the project calls for the team and expertise scales.",
    },
  ];

  const renderedCards = items.map((item) => {
    return <Card items={item} key={item.title} />;
  });

  return (
    <div className="what-do-container">
      <h1 className="headline">What makes us different</h1>
      <div className="do-page-container about-container">{renderedCards}</div>
    </div>
  );
}

export default AboutPage;
