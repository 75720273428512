function MindIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M125.018-.051C55.992-.051.034 56.087.034 125.335c0 64.497 48.544 117.611 110.974 124.598 3.062-7.933 12.814-26.028-12.608-43.202-7.59 2.693-25.256 9.193-32.003.141-2.963-3.975-.372-7.553-.63-11.901-.208-3.457-2.317-6.3-4.753-8.63 0 0-.73-2.406-.76-2.948-.132-2.282.455-3.76 2.412-4.858-2.134-.884-4.782-3.487-4.123-6.31 2.639-3.766 1.65-5.147-.164-7.804-3.135-.167-10.225-2.03-10.225-5.482 4.66-12.907 10.817-21.425 11.213-35.206-.74-3.597-1.318-7.415-1.57-11.41 1.352-11.84 1.566-15.312 8.828-32.652-9.234-5.316-9.234-8.893-9.234-8.893 9.895-10.628 42.25-30.843 91.768-30.185 23.998.319 38.32 10.192 51.252 31.798 7.175 11.98 13.239 28.977 12.08 42.94-6.284 44.273-21.2 48.26-33.075 58.89-3.39 20.908 5.465 43.625 12.232 57.208 35.062-22.209 58.354-61.42 58.354-106.094C250.002 56.087 194.044-.05 125.018-.05"
        fill="#f26921"
        fillRule="nonzero"
      />
      <path
        d="M115.715 135.335c5.023-3.024 11.554-1.406 14.582 3.617 3.027 5.034 1.403 11.561-3.619 14.585-5.027 3.027-11.556 1.407-14.587-3.618-3.028-5.023-1.406-11.554 3.624-14.584m1.643 46.984l4.48-8.582a29.394 29.394 0 007.362-1.114l6.813 6.888 9.253-5.573-2.9-9.243a29.23 29.23 0 004.424-5.99l9.685.054 2.6-10.487-8.582-4.478a29.245 29.245 0 00-1.112-7.364l6.887-6.808-5.575-9.258-9.24 2.905a29.28 29.28 0 00-5.99-4.43l.053-9.68-10.48-2.6-4.485 8.582c-2.466.048-4.936.42-7.365 1.108l-6.809-6.887-9.255 5.577 2.899 9.24a29.137 29.137 0 00-4.425 5.991l-9.68-.056-2.605 10.485 8.586 4.482a29.115 29.115 0 001.113 7.358l-6.888 6.816 5.573 9.254 9.243-2.903a28.955 28.955 0 005.986 4.428l-.05 9.678 10.484 2.607zM150.643 86.415a4.995 4.995 0 015.154 8.559 5.005 5.005 0 01-6.858-1.704 4.999 4.999 0 011.704-6.855m-18.878-5.307l-1.991 8.024 3.829 2.044c.04 1.617.28 3.24.728 4.83l-3.059 3.08 4.265 7.083 4.153-1.262a19.617 19.617 0 003.93 2.898l.014 4.344 8.026 1.99 2.042-3.831c1.618-.04 3.24-.278 4.832-.725l3.078 3.058 7.084-4.267-1.266-4.15a19.494 19.494 0 002.9-3.93l4.344-.014 1.991-8.026-3.83-2.045a19.585 19.585 0 00-.726-4.828l3.06-3.083-4.267-7.081-4.154 1.265a19.311 19.311 0 00-3.93-2.898l-.013-4.344-8.026-1.993-2.043 3.832a19.428 19.428 0 00-4.83.725l-3.078-3.058-7.085 4.263 1.263 4.154a19.54 19.54 0 00-2.898 3.931l-4.343.014z"
        fill="#f26921"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default MindIcon;
