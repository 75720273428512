import { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import "./Gallery.css";
import galleryIMGS from "../../pages/Work/images/galleryIMGS";

function Gallery({ currentItem, onClick, id }) {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  const currentIMGS = galleryIMGS[id];
  const renderLink = () => {
    if (currentItem.link) {
      return (
        <a href={currentItem.link} target="blank">
          {currentItem.title}
        </a>
      );
    }
  };

  const renderIMGS = currentIMGS.map((el, index) => {
    return (
      <Fragment key={`${currentItem.title} ${index}}`}>
        <img
          className={`gallery-image-${currentIMGS.length}`}
          src={el}
          alt={currentItem.title}
        />
      </Fragment>
    );
  });

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="portfolio-viewer-container">
        <button className="close-button" onClick={() => onClick()}>
          X
        </button>
        <div className="gallery-container">
          <div className="gallery-title-container">
            <h2>{currentItem.title}</h2>
          </div>
          <div className="gallery-body">
            <div className="main-image-container">
              <div className="rendered-image-container">{renderIMGS}</div>
            </div>
            <div className="description-container">
              <p className="work-description">
                {currentItem.description}
                <br className="link-break" />
                {renderLink()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(".modal-container")
  );
}

export default Gallery;
