import johnImg from "../../images/Jurmu.jpg";

function Jurmu() {
  return (
    <img
      src={johnImg}
      width={160}
      alt="John Jurmu"
      style={{ borderRadius: "110px" }}
    />
  );
}

export default Jurmu;
