const portfolio = [
  {
    title: "Delos Creative Branding",
    description:
      "While working on my personal branding I settled on the name Delos. Delos is the Greek God of islands, I decided that was fitting because at the time I was making the transition of working on Web advertising and Web Development, as I am creating an island for my clients in the vast sea of online content. The center is a classic ink pen representing my roots in print media",
  },
  {
    title: "Rocky Mountain Biometrx Branding",
    description:
      "Rocky Mountain Biometrx is a biometrics screening company that is based out of Great Falls Montana. They travel to businesses and do onsite health screenings for the companies employee’s and their family that are the company health insurance policy. In their branding they wanted to incorporate mountains and a heart beat. The colors are meant to convey calmness.",
    link: "https://rmbiometrx.com/",
  },
  {
    title: "Yellowstone News Group Logo",
    description:
      "After Adams Publishing group acquired thirteen medium and small sized newspapers across Montana I was tasked to redo their logo. The areas were taken into consideration and the fonts selected were reflective of what you would expect from a print journalism publication and fonts inspired by the small agricultural communities that they serve.",
  },
  {
    title: "Rocky Mountain Biometrx Web Portal",
    description:
      "Rocky Mountain Biometrx needed a web based portal to log patient test results onsite with a central database that can be accessed anytime anywhere. This portal had an administration side to log patient data, and a patient side where patients can log in to get their test results. Patients have 24/7 access to their test results and the app keeps track of test results year to year.",
  },
  {
    title: "RPG Calculator",
    description:
      "RPG calculator is a web application is a tool that helps people play tabletop RPG games (Example Dungeons and Dragons). The App not only allows users keep track of dice rolls and stats, the app also breaks down the Math to help people learn how to play the game. This app is intended for novices and experienced players alike. The app allows for customization to rule sets to allow for the app to be compatible for other games.",
    link: "https://rpg-calculator-octolaser.vercel.app/",
  },
  {
    title: "January Full Page Sale",
    description:
      "This is a call back to 70’s design with a modern flare. This was done specially because the Newsprint would enhance the coloring and the aesthetic of the theme. One added benefit was this stick out and would be instantly recognizable compared to the other advertisements in the publication.",
  },
  {
    title: "Old Chicago Speakeasy",
    description:
      "The owner of the Old Chicago, who franchised restaurants in Montana and Wyoming, asked the Chronicle to run ads made by another newspaper. As we began to work with it, we discovered the ad had troublesome watermarks and didn’t meet Old Chicago corporate branding requirements. Because of those issues and tight deadlines, I simply redesigned it from scratch. These new ads were used across Montana and Wyoming in a 2-year campaign.",
  },
  {
    title: "Cateye Cafe Farm Fresh",
    description:
      "The goal was simple, create a new look that would get new customers thinking about the café’s breakfast. After the campaign was seeing no results we took the initive to work on new creatives. The client was delighted, made no changes and requested that the ad be their new ad campaign. This brought new life for the remainder of the campaign.",
  },
  {
    title: "20 Under 40 Nominations",
    description:
      "This advertising campaign drew attention to nomination rounds for our 20 Under 40 event that highlights 20 individuals in the local community under the age of 40. The design features a spot light. This advertising campaign was award winning and drew over 300 nominations.",
  },
  {
    title: "Go Figure Booklet",
    description:
      "Go Figure Weight Loss Center needed to update their Digest sized pamphlet. This 20 page pamphlet is a comprehensive guide to their program. The product includes charts, vector images, and other graphics to keep readers interested. This book is given to every new patient that goes through their program.",
  },
  {
    title: "Prime Senior Magazine",
    description:
      "Prime Senior Magazine is a monthly publication for Gallatin Valley seniors. Needing a refreshed look, we re-imagined the publication front-to-back. We did extensive research and reached out to the reader base to ask what they wanted to see change. The redesign was well received, calls from many satisfied readers that the magazine was now easier to read and they loved the new content.",
  },
  {
    title: "Half Way Tee-Cent",
    description:
      "The Half Way Tee-Cent challenge was to create a new T-shirt design every day for a month. This is a collection of some of my designs from that month, made either in Illustrator or Photoshop for different and exciting effect. All the shirts can be produced. Some were designed for silk screen and others for induction T-shirt printing.",
  },
];

export default portfolio;
