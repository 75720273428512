function JumpButtonOutline(props) {
  return (
    <svg viewBox="0 0 872.47 1007.44" {...props}>
      <path
        d="M436.24 0L0 251.86v503.72l436.24 251.86 436.24-251.86V251.86L436.24 0zm0 855.48L113.7 296.84l322.53 104.88 322.53-104.88-322.52 558.64z"
        fill="#f26921"
      />
    </svg>
  );
}

export default JumpButtonOutline;
