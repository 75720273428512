import { useContext } from "react";
import NavigationContext from "../Context/navigation";
import "./MenuBar.css";
import LogoRound from "../images/LogoRound";

function MenuBar() {
  const { currentPath, navigate } = useContext(NavigationContext);
  const nav = ["Services", "Work", "About", "Contact"];

  const handleNavClick = (event) => {
    event.preventDefault();
    navigate("/" + event.target.value);
  };

  const renderedNavItems = () => {
    const navButtons = nav.map((button) => {
      const value = button.toLocaleLowerCase();
      let path = "services";
      if (currentPath !== "/") path = currentPath.slice(1);

      return (
        <button
          className={path === value ? "active-nav-button" : "nav-button"}
          key={button}
          onClick={handleNavClick}
          value={value}
        >
          {button}
        </button>
      );
    });
    return <div className="nav-items">{navButtons}</div>;
  };

  return (
    <div className="menu-container">
      <LogoRound className="logo-round" />
      {renderedNavItems()}
    </div>
  );
}

export default MenuBar;
