import "./SplashLogo.css";
import LogoNoBackground from "../images/Logo-NoBackground";
function SplashLogo() {
  return (
    <div className="container">
      <div className="logo-box">
        <LogoNoBackground className="logo" width={1} />
        <div className="font-container">
          <p className="pre-font">Welcome to</p>
          <p className="logo-font">Delos Creative</p>
          <hr className="line" />
          <p className="tag-line">Branding | Website Development | Marketing</p>
        </div>
      </div>
    </div>
  );
}

export default SplashLogo;
