import { useState } from "react";
import "./Work.css";
import portfolio from "./items";
import Gallery from "../../componets/Gallery/Gallery";
import thumbnails from "./images/thumb/thumb";

function WorkPage({ setGallery }) {
  const [currentItem, setCurrentItem] = useState({});
  const [id, setId] = useState(0);
  const [showGallery, setShowGallery] = useState(false);

  const handleGallery = (item, index) => {
    setShowGallery(true);
    setId(index);
    setCurrentItem(item);
    setGallery(showGallery);
  };

  const handleGalleryClose = () => {
    setShowGallery(false);
    setGallery(showGallery);
  };

  const renderedCards = portfolio.map((el, index) => {
    return (
      <div
        className="work-card"
        key={`${el.title} container`}
        onClick={() => handleGallery(el, index)}
      >
        <div className="feature-gallery">
          <div className="thumb">
            <img
              className="thumbnail"
              src={thumbnails[index]}
              alt={`${el.title} thumb`}
              title={el.title}
            />
          </div>

          <div
            className={showGallery ? "fg-overlay" : "fg-overlay cursor-zoom"}
          >
            <h2>{el.title}</h2>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="work-container">
      {showGallery && (
        <Gallery
          currentItem={currentItem}
          id={id}
          thumbnail={thumbnails[id]}
          onClick={() => handleGalleryClose()}
        />
      )}
      {renderedCards}
    </div>
  );
}

export default WorkPage;
