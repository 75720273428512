function CollegeM(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      {...props}
    >
      <g transform="matrix(.94873 0 0 .94932 -10.729 -8.251)">
        <circle cx={143.118} cy={140.339} r={124.969} fill="none" />
        <clipPath id="prefix__a">
          <circle cx={143.118} cy={140.339} r={124.969} />
        </clipPath>
        <g clipPath="url(#prefix__a)">
          <path
            d="M271.416 143.295l-16.283-10.49-8.78-7.47-5.637-3.844-4.404-3.528-17.676-1.512-30.86-10.071-21.723-4.329s-7.26-7.346-18.64-3.626-23.72 6.407-25.37 6.823c-1.65.417-12.43 4.629-20.389 2.378-7.96-2.25-26.268-5.819-29.502-6.985-3.232-1.168-13.108-1.723-17.563-5.474-4.454-3.751-11.225-7.303-14.404-6.786-3.18.515-8.794.498-11.98.58-3.186.085-10.536 1.485-19.66 6.76-9.126 5.275-5.504 204.487-5.504 204.487l281.518 3.484-13.143-160.397z"
            fill="#f26921"
          />
        </g>
        <circle
          cx={143.118}
          cy={140.339}
          r={124.969}
          fill="none"
          stroke="#f26921"
          strokeWidth={13.17}
        />
      </g>
      <path
        d="M133.192 208.402l18.289.742.756-7.784-2.585-.704 2.928-32.734 3.29.032 1.592-13.785-13.55-.292-10.242 18.778-4.894-18.016-13.726-.556-1.234 13.393 2.88.003-5.034 34.09-3.56.727-.54 6.071 18.02.246 1.037-7.363-3.493-.325 2.629-19.424 5.24 11.696 8.087-11.539-1.506 19.105-3.757.545-.627 7.094zM106.699 90.641l-28.268 15.343-15.842 18.937-37.748 16.884-19.553 2.687-9.02 1.74.543-11.667 24.543-9.594 11.567-5.895 17.43-3.16 11.182-2.49 10.268-12.437 4.479-8.857 3.675-7.192 26.744 5.701zM233.856 225.134l-13.723-13.349-2.34-1.365-2.126-8.083-14.846-4.153-1.178-6.532-5.86-5.206-4.031-11.006-1.575-3.008-.49-5.352-4.115-5.496-3.876-4.546 1.662-5.145-.553-7.46 8.012 6.92 3.284 6.411 3.612 11.2 5.065 7.858.92 9.256 5.866 5.136 5.46 5.595 9.593 3.194 2.68 10.922 8.554 5.7 5.879 8.484-5.874.025zM223.62 121.894l-9.773-3.034-8.502-4.739-8.15-4.965-15.936-.967 1.366 3.33 13.796 2.193 6.258 4.828 8.69 4.3 16.16 2.999-3.909-3.945zM154.497 95.619l-14.23.215-8.445-4.394-16.451 4.585 17.035.403 6.788 4.348 15.709.502 4.47-3.127-4.876-2.532z"
        fill="#fff"
      />
    </svg>
  );
}

export default CollegeM;
