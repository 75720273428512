function PencilIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M125.501 183.592l-74.14-74.087 10.278-10.352 74.141 74.086 33.525 9.413 11.351-11.258-9.503-34.956-74.14-74.087 10.278-10.353 74.134 74.081.016-.016 21.268 79.4-77.208-21.87zm37.355-38.263l-20.141 20.283-73.93-74.301 20.142-20.283 73.929 74.3zM28.208 87.36l53.315-54.483L93.57 44.67 40.255 99.153 28.208 87.36zM125.237-.062C56.209-.062.25 55.908.25 124.95s55.958 125.01 124.986 125.01 124.986-55.969 124.986-125.01S194.265-.061 125.237-.061"
        fill="#f26921"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default PencilIcon;
