function ComputerIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M70.37 155.525h108.592c1.745 0 3.174-.813 3.174-1.835 0-1.02-1.43-1.841-3.174-1.841H70.37c-1.748 0-3.18.82-3.18 1.841 0 1.022 1.432 1.835 3.18 1.835M122.886 108.03a3.708 3.708 0 00-.02 5.263 3.758 3.758 0 005.293.021 3.707 3.707 0 00.022-5.264 3.76 3.76 0 00-5.295-.02M68.362 161.66h112.837c1.827 0 3.301-.831 3.301-1.842 0-1.02-1.474-1.841-3.301-1.841H68.362c-1.814 0-3.305.828-3.29 1.841 0 1.014 1.483 1.841 3.29 1.841"
        fill="#f26921"
        fillRule="nonzero"
      />
      <path
        d="M162.219 116.01h-7.696c.23-1.547.353-3.131.353-4.744 0-16.9-13.1-30.6-29.26-30.6-16.157 0-29.257 13.7-29.257 30.6 0 1.613.121 3.197.351 4.743h-8.55a37.28 37.28 0 01-.452-5.777c0-20.583 16.781-37.27 37.481-37.27 20.699 0 37.48 16.687 37.48 37.27a37.28 37.28 0 01-.45 5.777M143.98 93.32l-10.176 19.866c-.114.308-.325.798-.474 1.092 0 0-.814 1.611-1.716 2.5-3.39 3.347-8.866 3.323-12.23-.049a8.566 8.566 0 01.046-12.163c.902-.89 2.532-1.684 2.532-1.684.297-.143.788-.354 1.098-.464l20.026-8.93c.312-.11 1.004-.48.894-.168m-42.638 16.25c-.102-.88-.16-1.776-.16-2.682 0-12.908 10.591-23.37 23.657-23.37a23.73 23.73 0 0112.983 3.836l-3.994 3.874a27.241 27.241 0 00-6.648-.825c-12.254 0-22.588 8.096-25.838 19.167m85.294-49.462H64.735v70.135h121.901V60.107zM185.94 171.178H64.807c-1.955 0-3.537.82-3.537 1.84 0 1.014 1.588 1.832 3.537 1.832H185.94c1.952 0 3.544-.818 3.544-1.831 0-1.02-1.592-1.841-3.544-1.841M66.035 168.018h117.64c1.904 0 3.448-.816 3.448-1.84 0-1.021-1.544-1.839-3.449-1.839H66.034c-1.903 0-3.442.818-3.44 1.838 0 1.025 1.537 1.841 3.44 1.841"
        fill="#f26921"
        fillRule="nonzero"
      />
      <path
        d="M206.605 198.358H44.16c-2.348 0-4.269-1.9-4.269-4.26l15.643-46.485a4.262 4.262 0 014.266-4.258h131.169a4.27 4.27 0 014.261 4.258l15.636 46.484c0 2.355-1.91 4.261-4.261 4.261M56.717 55.393c0-2.344 1.914-4.268 4.262-4.268h129.4a4.272 4.272 0 014.262 4.268v79.138a4.258 4.258 0 01-4.263 4.257H60.98a4.26 4.26 0 01-4.262-4.257V55.393zM125.007.03C55.966.03 0 55.987 0 125.01c0 69.025 55.967 124.982 125.005 124.982 69.04 0 125.006-55.957 125.006-124.981C250.012 55.987 194.045.03 125.006.03"
        fill="#f26921"
        fillRule="nonzero"
      />
      <path
        d="M117.433 177.616l-4.977 11.065h30.334l-4.255-11.065h-21.102z"
        fill="#f26921"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default ComputerIcon;
