function CalendarIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path fill="#f26921" d="M71.746 3.49h-18.7v29.256h18.7z" />
      <path
        d="M221.34 176.503h-47.687l2.803 43.412H36.216V78.27H221.34v98.234zM211.051 16.23v27.674h-44.874V16.231H84.838v27.674H39.955V16.231H6.304v231.016h172.03l65.443-62.167V16.23h-32.726z"
        fill="#f26921"
        fillRule="nonzero"
      />
      <path fill="#f26921" d="M198.919 1.602h-18.7V30.86h18.7z" />
    </svg>
  );
}

export default CalendarIcon;
