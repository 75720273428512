import "./Contact.css";
import PhoneIcon from "./PhoneIcon";
import EmailIcon from "./EmailIcon";
import CalendarIcon from "./CalendarIcon";

function ContactPage() {
  const date = new Date();
  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div className="contact-page-container">
      <div className="information-container">
        <div className="information-v-align">
          <h1 className="contact-headline">
            Contact us for a free consultation!
          </h1>
          <p className="contact-body">
            Whether you are just starting your business or looking to refresh or
            upgrade your technology infrastructure we would be happy to sit down
            and talk with you. No obligation or risk to you, only a little bit
            of your time.
          </p>
          <div className="schedule-container">
            <div className="calendar-container">
              <CalendarIcon className="scedule-appointment-icon" />
              <p className="month">{months[date.getMonth()]}</p>
              <p className="date">{date.getDate()}</p>
            </div>
            <div
              className="schedule-button"
              onClick={(e) => {
                window.location.href =
                  "mailto:john@deloscreative.com?subject=Schedule Appointment";
                e.preventDefault();
              }}
            >
              Schedule Your Appointment!
            </div>
          </div>
          <p className="contact-body">
            If you have any other questions feel free to use the other icons to
            call or email us.
          </p>
        </div>
      </div>
      <div className="contact-container">
        <a href="tel:14062200322" style={{ textDecoration: "none" }}>
          <div className="contact-button">
            <PhoneIcon alt="Phone Icon" className="contact-icon" />
            <div className="contact-button-text-container">
              <p className="contact-button-text">Call Today</p>
              <p className="contact-button-detail">406.220.0322</p>
            </div>
          </div>
        </a>
        <div
          className="contact-button"
          onClick={(e) => {
            window.location.href = "mailto:john@deloscreative.com";
            e.preventDefault();
          }}
        >
          <EmailIcon alt="email icom" className="contact-icon" />
          <div className="contact-button-text-container">
            <p className="contact-button-text">Email Us</p>
            <p className="contact-button-detail email-text">
              john@deloscreative.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
