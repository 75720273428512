function PhoneIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M163.52 244.856c-2.149 2.792-5.044 4.187-8.687 4.197l-42.619-.079c-2.04.11-4.21-.23-6.514-1.034-2.304-.8-4.318-1.842-6.033-3.124-.105-.105-.455-.399-1.044-.884-.586-.477-1.313-1.147-2.17-2.003-2.035-2.03-4.985-5.673-8.842-10.92-3.861-5.243-7.717-12.534-11.572-21.852-3.863-9.32-7.234-20.895-10.125-34.72-2.895-13.823-4.28-30.231-4.17-49.208-.107-14.9.757-28.198 2.583-39.89 1.825-11.691 4.109-21.907 6.843-30.654 2.738-8.742 5.687-16.2 8.852-22.37 3.163-6.173 6.167-11.219 9.012-15.138 2.84-3.92 5.278-6.792 7.319-8.62 2.035-1.825 3.164-2.843 3.38-3.059 1.717-1.29 3.724-2.339 6.029-3.147 2.309-.813 4.474-1.16 6.517-1.062l42.778-.08c3.002-.004 5.467.951 7.4 2.88 1.392 1.39 2.223 3.024 2.489 4.903a15.753 15.753 0 01-.24 5.706l-15.454 49.89c-.75 2.682-2.356 4.828-4.827 6.443-2.463 1.612-5.092 2.424-7.882 2.429l-15.761.028c-.428.003-.965.163-1.61.487-.643.321-1.124.645-1.447.968-1.823 2.681-3.434 6.224-4.828 10.625-1.289 3.86-2.44 8.985-3.462 15.363-1.018 6.384-1.473 14.456-1.368 24.209-.116 9.758.338 17.877 1.358 24.364 1.014 6.482 2.14 11.63 3.372 15.437 1.23 3.804 2.381 6.505 3.459 8.113l1.604 2.406c.215.215.642.483 1.287.804.643.319 1.178.479 1.608.478l18.495-.197c3.645.213 6.755 1.597 9.327 4.165 1.824 1.821 2.948 3.588 3.375 5.3l.161.16 12.694 49.35c.854 3.649.428 6.752-1.287 9.336"
        fill="#fff"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default PhoneIcon;
