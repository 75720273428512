import { useContext } from "react";
import NavigationContext from "../Context/navigation";

function Route({ path, children }) {
  const { currentPath, navigate } = useContext(NavigationContext);
  if (
    currentPath !== "/" &&
    currentPath !== "/services" &&
    currentPath !== "/work" &&
    currentPath !== "/about" &&
    currentPath !== "/contact" &&
    currentPath !== "/sitemap.xml"
  ) {
    navigate("/404");
  }
  if (path === currentPath) return children;
  if (path !== currentPath) return null;
}

export default Route;
