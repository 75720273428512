import thumb0 from "./thumb0.png";
import thumb1 from "./thumb1.jpg";
import thumb2 from "./thumb2.png";
import thumb3 from "./thumb3.jpg";
import thumb4 from "./thumb4.png";
import thumb5 from "./thumb5.png";
import thumb6 from "./thumb6.jpg";
import thumb7 from "./thumb7.png";
import thumb8 from "./thumb8.png";
import thumb9 from "./thumb9.png";
import thumb10 from "./thumb10.jpg";
import thumb11 from "./thumb11.png";

const thumbnails = [
  thumb0,
  thumb1,
  thumb2,
  thumb3,
  thumb4,
  thumb5,
  thumb6,
  thumb7,
  thumb8,
  thumb9,
  thumb10,
  thumb11,
];

export default thumbnails;
