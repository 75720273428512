function LogoRound(props) {
  return (
    <svg viewBox="0 0 1019.76 1019.76" {...props}>
      <circle cx={509.88} cy={509.88} r={509.88} fill="#f15d22" />
      <g fill="#404041">
        <path d="M592.38 1013.02c51.25-8.34 99.9-24.33 144.81-46.74-32.48-32.07-82.99-47.58-144.81 46.74zM282.57 966.28c44.91 22.41 93.57 38.4 144.81 46.74-61.82-94.32-112.34-78.81-144.81-46.74z" />
        <path d="M509.88 0C228.28 0 0 228.28 0 509.88 0 684.3 87.63 838.19 221.21 930.13c-24.08-119.37-58.19-329.74-45.62-515.73 20.06 329.86 61.99 398.57 162.11 460.1 68.73 39.83 101.01 96.96 116.33 142.16 10.91 1.19 21.92 2.04 33.03 2.53-20.68-82.66-61.16-138.23-109.65-160.34 70.89-159 122.09-757.57 122.09-757.57v746.83c-13.39 4.22-23.16 16.59-23.16 31.38 0 18.26 14.8 33.06 33.05 33.06s33.06-14.8 33.06-33.06c0-14.43-9.31-26.58-22.21-31.08V101.28s51.2 598.58 122.09 757.57c-48.63 22.17-89.67 77.39-110.35 160.35a506.97 506.97 0 0033.74-2.55c15.32-45.2 47.6-102.33 116.34-142.16 100.12-61.52 142.05-130.24 162.11-460.1 12.57 185.99-21.55 396.36-45.63 515.73 133.58-91.93 221.21-245.83 221.21-420.25C1019.76 228.28 791.48 0 509.88 0z" />
      </g>
    </svg>
  );
}

export default LogoRound;
