import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { NavigationProvider } from "./Context/navigation";
import "./fonts/Futura.ttc";
import "./fonts/Trajan Pro Regular.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NavigationProvider>
    <App />
  </NavigationProvider>
);
