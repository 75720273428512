function EmailIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M248.191 217.048H1.306V52.44l123.433 83.04 123.452-83.04v164.61z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        d="M248.191 31.211H1.306v.679L124.74 111.46l123.452-80.25z"
        fill="#fff"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default EmailIcon;
