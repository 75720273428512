import "./Card.css";

function Card({ items }) {
  return (
    <div className="card-container">
      <h2 className="card-header">{items.title}</h2>
      <div className="card-body-container">
        <div className="card-icon-container">{items.icon}</div>
        <div className="card-description-container">
          <p className="card-description">
            {items.secondHeading && (
              <span className="card-second-heading">{items.secondHeading}</span>
            )}
            {items.secondHeading && <br />}
            {items.description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card;
