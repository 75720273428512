import imgs0 from "./0/imgs0";
import imgs1 from "./1/imgs1";
import imgs2 from "./2/imgs2";
import imgs3 from "./3/imgs3";
import imgs4 from "./4/imgs4";
import imgs5 from "./5/imgs5";
import imgs6 from "./6/imgs6";
import imgs7 from "./7/imgs7";
import imgs8 from "./8/imgs8";
import imgs9 from "./9/imgs9";
import imgs10 from "./10/imgs10";
import imgs11 from "./11/imgs11";

const galleryIMGS = [
  imgs0,
  imgs1,
  imgs2,
  imgs3,
  imgs4,
  imgs5,
  imgs6,
  imgs7,
  imgs8,
  imgs9,
  imgs10,
  imgs11,
];

export default galleryIMGS;
