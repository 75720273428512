import "./Services.css";
import Card from "../../componets/Card";
import ComputerIcon from "../../images/ComputerIcon";
import MindIcon from "../../images/MindIcon";
import PencilIcon from "../../images/PencilIcon";

function ServicesPage() {
  const items = [
    {
      title: "Branding",
      icon: <PencilIcon alt="pencil icon" className="card-icon" />,
      description:
        "Strong branding helps you stand out from your competition and makes your business look credible. It's rare when you get a second chance to make a first impression, your logo is often the first part of your business that your customer notices. Quality logo files can reduce on cost through many aspects of your branding and content.",
    },
    {
      title: "Web Development",
      icon: <ComputerIcon alt="computer icon" className="card-icon" />,
      description:
        "Web and app solutions built from the ground up to work and look great on any platform. We use the most current software standards to assure reliability, speed, and visibility. Your site and hosting scales on demand, meaning you only pay for what you need when you need it.",
    },
    {
      title: "Marketing",
      icon: <MindIcon alt="mind icon" className="card-icon" />,
      description:
        "Targeted marketing strategies to make sure your message is reaching your customers. Have peace of mind with reports and tracking. Gain valuable insight how your customers shop and what methods and tactics are best to reach them.",
    },
  ];

  const renderedCards = items.map((item) => {
    return <Card items={item} key={item.title} />;
  });

  return (
    <div className="what-do-container">
      <h1 className="headline">Services We Offer</h1>
      <div className="do-page-container">{renderedCards}</div>
    </div>
  );
}

export default ServicesPage;
