function LogoNoBackground(props) {
  return (
    <svg viewBox="0 0 689.48 1009.4" {...props}>
      <path
        d="M387.66 1009.4s0-141.2 133.22-218.4c102.43-62.94 145.31-133.23 165.84-470.68 19.26 284.97-68.66 626.2-68.66 626.2s-95.14-232.91-230.39 62.88zM301.81 1009.4s0-141.2-133.22-218.4C66.17 728.06 23.28 657.77 2.76 320.32c-19.26 284.97 68.66 626.2 68.66 626.2s95.14-232.91 230.39 62.88z"
        fill="#f26921"
      />
      <path
        d="M355.35 0v764.31c13.19 4.61 22.72 17.03 22.72 31.8 0 18.68-15.14 33.82-33.82 33.82s-33.81-15.14-33.81-33.82c0-15.13 10-27.78 23.7-32.1V0s-52.38 612.34-124.9 774.99c60.55 27.6 109.43 105.5 123.38 223.57v10.84h22.92v-1.25c12.31-123.65 62.5-204.79 124.72-233.16C407.72 612.34 355.35 0 355.35 0z"
        fill="#f26921"
      />
    </svg>
  );
}

export default LogoNoBackground;
