import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <p className="footer-text">
        {`Coded and designed using React.js | ${"\u00A9"} 2024`} Delos Creative
      </p>
    </div>
  );
}

export default Footer;
