function MissingPageIcon(props) {
  return (
    <svg
      viewBox="0 0 250 250"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M222.469 67.558h-58.798V8.33l58.798 59.228z"
        fill="#f26921"
        fillRule="nonzero"
      />
      <path
        d="M68.771 239.51H39.19c-2.182 0-3.993-1.801-3.993-3.993V13.294c0-2.221 1.831-3.992 3.993-3.992h111.281v73.457l72.327.01v152.748a3.995 3.995 0 01-3.983 3.993H103.421a19.243 19.243 0 002.164-8.892c0-10.714-8.733-19.412-19.489-19.412s-19.489 8.698-19.489 19.412c0 3.204.782 6.228 2.164 8.892zm3.379-38.597l-.2-5.734c-.661-11.267 3.072-22.774 12.998-34.712 7.085-8.385 12.819-15.46 12.819-22.985 0-7.725-5.084-12.818-16.111-13.258-7.275 0-16.1 2.652-21.834 6.624l-7.515-24.075c7.955-4.643 21.194-9.076 36.874-9.076 29.129 0 42.357 16.12 42.357 34.472 0 16.801-10.376 27.858-18.762 37.134-8.155 9.056-11.457 17.671-11.237 27.618v3.972l-29.389.02z"
        fill="#f26921"
      />
    </svg>
  );
}

export default MissingPageIcon;
